<template>
  <div class="w-100 h-100">
    <div class="row mx-0 w-100 h-100 justify-content-center">
      <div class="col-12 px-0 ">
        <div class="row m-0 justify-content-center bg_overlay">
          <div class="col-md-5 d-none d-md-block mb-md-3">
            <DonorFinderButton @finder="finder"/>
            <DonorSignupButton @signup="signup"/>
          </div>
          <div class="col-md-6">
            <div class="row m-0 justify-content-center">
              <div class="col-auto text-center p-0 mb-3 card_heading px-3 py-2">
                DONOR LOGIN
              </div>
            </div>
            <div class="row mx-0 mt-4 justify-content-center">
              <div class="col-12">
                <form @submit.prevent="signin" class="w-100 h-100">
                  <div class="row mx-0 w-100 justify-content-center">
                    <div class="col-11 mb-3">
                      <TextField type="text" placeholder="Username / Email" v-model="username" id="username" color="gold" autocomplete="username" />
                    </div>
                    <div class="col-11">
                      <TextField type="password" placeholder="Password" v-model="password" id="password" color="gold" autocomplete="current-password" />
                    </div>
                    <div class="col-10 mb-3 font10 red-text" :class="{'opacity': userStatus !== 'error'}">
                      Incorrect Username/Password. Please try again.
                    </div>
                    <div class="col-11 mb-3">
                      <div class="row justify-content-between align-items-center">
                        <div class="col col-md-6">
                          <Button color="gold" width="100" btnText="Log In" icon="arrow" type="submit"  :isLoading="userStatus === 'logging in' || userStatus === 'loading'">
                            <IconArrowForward color="black" size="size16" />
                          </Button>
                        </div>
                        <div class="col-auto cursor font12 bold" @click="forgot">
                          Forgot Password?
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-12 mb-3 d-none d-md-block">
                <Button color="transparent" btnText="Anonymous Donation? Click here for Guest Checkout" icon2="arrow" size="small" @click="close">
                  <template v-slot:iconStart>
                    <IconNoUser2 color="black" size="size16" />
                  </template>
                </Button>
              </div>
              <div class="col-12 mb-3 d-md-none">
                <div class="row mx-0 justify-content-center align-items-center">
                  <div class="col-auto p-0">
                    <IconNoUser2 color="black" size="size16" />
                  </div>
                  <div class="col-auto pe-0">
                    Anonymous Donation?
                  </div>
                </div>
                <div class="row mx-0 mt-1">
                  <div class="col-12 p-0">
                    <Button color="transparent" btnText="Click here for Guest Checkout" icon="arrow" size="small" @click="close">
                      <IconArrowForward size="size16" />
                    </Button>
                  </div>
                </div>
                <div class="row mx-0 mt-3 justify-content-center align-items-center">
                  <div class="col-12 p-0">
                    <Button color="white" btnText="Find your Donor Details" icon2="arrow" size="small" @buttonClicked="finder">
                      <template v-slot:iconStart>
                        <IconSearchUser size="size16" />
                      </template>
                    </Button>
                  </div>
                </div>
                <div class="row mx-0 my-3">
                  <div class="col-12 p-0">
                    <Button color="transparent" btnText="Register a New Donor Profile" class="green-text-dark" icon="arrow" size="small" @buttonClicked="signup">
                      <IconArrowForward size="size16" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import { Capacitor } from '@capacitor/core'
import { SavePassword } from 'capacitor-ios-autofill-save-password'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconNoUser2: defineAsyncComponent(() => import('@/components/icons/IconNoUser2.vue')),
    IconSearchUser: defineAsyncComponent(() => import('@/components/icons/IconSearchUser.vue')),
    DonorSignupButton: defineAsyncComponent(() => import('../components/DonorSignupButton.vue')),
    DonorFinderButton: defineAsyncComponent(() => import('../components/DonorFinderButton.vue'))
  },
  name: 'Login',
  props: ['isRedirect'],
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['userStatus'])
  },
  methods: {
    ...mapActions([
      'login', 'setUserStatus'
    ]),
    async signin () {
      const auth = {
        username: this.username,
        password: this.password
      }
      await this.login(auth)
      if (this.userStatus === 'logged in') {
        if (Capacitor.getPlatform() === 'ios') {
          console.log('isios')

          SavePassword.promptDialog({
            username: this.username,
            password: this.password
          })
            .then(() => console.log('promptDialog success'))
            .catch((err) => console.error('promptDialog failure', err))
        }
        if (this.isRedirect) {
          this.$emit('close')
          this.$router.push('/profile/0')
        } else {
          this.$emit('loggedIn')
        }
      }
    },
    async setLoginStatus (val) {
      await this.setUserStatus(val)
    },
    signup () {
      this.setLoginStatus(null)
      this.$emit('signup')
    },
    forgot () {
      this.setLoginStatus(null)
      this.$emit('forgot')
    },
    finder () {
      this.setLoginStatus(null)
      this.$emit('finder')
    },
    close () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green-color);
  border-bottom: 2px solid var(--gold-color);
}
.offwhite_bg {
  background-color: rgb(249, 250, 251);
}
.background-green {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  color: #fff;
  font-weight: bold;
}
.green-text-dark {
  color: var(--green-color-dark);
}
.border_radius {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.04) 7px 7px 15px 0px;
  cursor: pointer;
}
.cursor:hover {
  text-decoration: underline;
}
.bg_overlay {
  background-image: linear-gradient(rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0.7) 10%, rgba(249, 250, 251, 1) 100%);
}
</style>
